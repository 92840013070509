import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Helmet from 'react-helmet';
import { H1, P } from '@insights-ltd/design-library/components';
import SelfServiceLayout from 'components/layout/SelfServiceLayout';
import { useForgottenPassword } from 'api';
import { FormData } from 'pages/ForgottenPassword/types';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';
import { styled } from '@mui/material';

type Props = {
  update: (formData: FormData) => void;
  setShowConfirmation: (showConfirmation: boolean) => void;
  backUrl: string;
};

const StyledH1 = styled(H1)(({ theme }) => ({
  marginBottom: theme.spacing(spacingSizeMap.XS),
}));

const UserDetails = ({ update, setShowConfirmation, backUrl }: Props) => {
  const { t } = useTranslation();
  const { mutate, isPending, isError } = useForgottenPassword();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();

  const onSubmit = (formData: FormData) =>
    mutate(formData, {
      onSuccess: () => {
        update(formData);
        setShowConfirmation(true);
      },
    });

  return (
    <>
      <Helmet>
        <title>{t('ui.event-management.title.forgotten-password')}</title>
      </Helmet>
      <SelfServiceLayout>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Card
            sx={(theme) => ({
              maxWidth: '31rem',
              padding: `0 ${theme.spacing(spacingSizeMap.XS)}`,
            })}
          >
            <CardContent>
              <Grid container direction="column" justifyContent="space-between">
                <Grid
                  sx={(theme) => ({
                    flexBasis: 'auto',
                    width: '100%',
                    margin: `${theme.spacing(spacingSizeMap.XS)} 0`,
                  })}
                  item
                  xs={12}
                >
                  <P variant="body-bold">
                    <Link component={RouterLink} to={backUrl}>
                      {t('ui.event-management.forgotten-password.back')}
                    </Link>
                  </P>
                </Grid>
                <Grid
                  sx={(theme) => ({
                    flexBasis: 'auto',
                    width: '100%',
                    margin: `${theme.spacing(spacingSizeMap.XS)} 0`,
                  })}
                  item
                  xs={12}
                >
                  <StyledH1 variant="h3">
                    {t('ui.event-management.forgotten-password.heading')}
                  </StyledH1>
                  <P variant="body" color="textSecondary">
                    {t('ui.event-management.forgotten-password.sub-heading')}
                  </P>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={(theme) => ({
                    flexBasis: 'auto',
                    width: '100%',
                    margin: `${theme.spacing(spacingSizeMap.XS)} 0`,
                  })}
                >
                  <FormControl
                    variant="outlined"
                    sx={(theme) => ({
                      flexBasis: 'auto',
                      width: '100%',
                      margin: `${theme.spacing(spacingSizeMap.XS)} 0`,
                    })}
                  >
                    <TextField
                      id="emailAddress"
                      label={t(
                        'ui.event-management.forgotten-password.email.input-label',
                      )}
                      type="email"
                      variant="outlined"
                      error={Boolean(errors.emailAddress)}
                      helperText={
                        errors.emailAddress &&
                        t(
                          'ui.event-management.forgotten-password.email.error.required',
                        )
                      }
                      {...register('emailAddress', { required: true })}
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  container
                  justifyContent="flex-end"
                  sx={(theme) => ({
                    flexBasis: 'auto',
                    width: '100%',
                    margin: `${theme.spacing(spacingSizeMap.XS)} 0`,
                  })}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    sx={{
                      width: '15rem',
                      height: '3rem',
                    }}
                    disabled={isPending}
                  >
                    {t('ui.event-management.forgotten-password.cta')}
                  </Button>
                </Grid>
              </Grid>
              <Snackbar open={isError} autoHideDuration={6000}>
                <Alert elevation={6} variant="filled" severity="error">
                  {t('ui.event-management.forgotten-password.server.error')}
                </Alert>
              </Snackbar>
            </CardContent>
          </Card>
        </form>
      </SelfServiceLayout>
    </>
  );
};

export default UserDetails;
